import React from 'react';
import styled from 'styled-components';
import {Link as LinkIcon} from '@styled-icons/boxicons-regular/Link';
import {ArrowIosBack as ArrowPrevious} from '@styled-icons/evaicons-solid/ArrowIosBack';
import {ArrowIosForward as ArrowNext} from '@styled-icons/evaicons-solid/ArrowIosForward';
import {message} from 'antd';
import {useOutlet, getOutlet} from 'reconnect.js';
import qs from 'query-string';
import {Helmet} from 'react-helmet';
import tracker from 'rev.sdk.js/Utils/Tracker';
import RichTextPreview from 'rev.sdk.js/Components/RichTextPreview';
import BreadcrumbBar from 'rev.sdk.js/Templates/ProductList/BreadcrumbBar';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import Config from '../../../data.json';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';
import BrowseHeader from '../../Components/BrowseHeader';
import {formatDateTimeStr} from '../../Utils/TimeUtil';
import config from '../../../data.json';
import * as BuildUtil from '../../Utils/BuildUtil';

const LocaleOutlet = getOutlet('locale');
const L = AppActions.L;
const F = AppActions.F;

function ArticleDetail(props) {
  const {
    pageContext: {
      listViewPath = '/articles',
      prefixPath = '/article',
      prebuiltArticle = null,
      categoryDisplayMap = 'articleCategoryDisplayMap',
    },
  } = props;
  const [dimension] = useOutlet('dimension');
  const [article, setArticle] = React.useState(prebuiltArticle);
  const [nextArticle, setNextArticle] = React.useState(null);
  const [previousArticle, setPreviousArticle] = React.useState(null);
  const params = qs.parse(props.location.search);
  const {id} = params;
  const mobile = dimension.innerWidth < 720;
  const isPrebuilt = !!prebuiltArticle;

  React.useEffect(() => {
    function triggerTracker(article) {
      tracker.viewItem({
        name: 'Article_Default',
        item: {...article, price: 0},
      });
    }

    async function fetchData() {
      AppActions.setLoading(true);
      try {
        const resp = await JStorage.fetchOneDocument(
          'Article_Default',
          {id},
          null,
          {anonymous: true},
        );

        triggerTracker(resp);
        setArticle(resp);

        const next = await JStorage.fetchDocuments(
          'Article_Default',
          {
            created: {$gt: resp.created},
            labels: {$regex: 'blog'},
          },
          ['created'],
          {
            offset: 0,
            limit: 1,
          },
          null,
          {anonymous: true},
        );
        setNextArticle(next.results.length > 0 ? next.results[0] : null);

        const previous = await JStorage.fetchDocuments(
          'Article_Default',
          {
            created: {$lt: resp.created},
            labels: {$regex: 'blog'},
          },
          ['-created'],
          {
            offset: 0,
            limit: 1,
          },
          null,
          {anonymous: true},
        );
        setPreviousArticle(
          previous.results.length > 0 ? previous.results[0] : null,
        );
      } catch (ex) {
        console.warn(ex);
      }
      AppActions.setLoading(false);
    }

    if (!isPrebuilt) {
      fetchData();
    } else {
      // waiting for tracker initial setup.
      setTimeout(
        () =>
          triggerTracker({...prebuiltArticle, id: prebuiltArticle._id?.$oid}),
        2500,
      );
    }
  }, [id, isPrebuilt, prebuiltArticle]);

  async function copyShareUrl() {
    let _url = article.prebuilt_url
      ? `${Config.webHost}${article.prebuilt_url}`
      : config.allowBuildAllArticles
      ? `${Config.webHost}${BuildUtil.getArticlePath(article)}`
      : AppActions.getReurl({
          title: encodeURIComponent(article.title),
          image: article.og_image || '',
          redirectUrl: `${Config.webHost}/article?id=${article.id}`,
        });

    try {
      await navigator.clipboard.writeText(_url);
      tracker.share({
        name: 'article',
        item: article,
        method: 'url',
      });
      message.success(`已複製分享連結`);
    } catch (err) {
      console.log(err);
      message.warn(`無法複製連結`);
    }
  }

  function renderCustomSection({route, sectionId, params}) {
    if (sectionId === 'A') {
      return <BrowseHeader />;
    }

    if (['B', 'D'].indexOf(sectionId) > -1) {
      return null;
    }
  }

  const _renderCustomSection = React.useCallback(
    (sectionId) => {
      if (renderCustomSection && typeof renderCustomSection === 'function') {
        return renderCustomSection({
          route: prefixPath,
          sectionId,
          params,
        });
      }

      return null;
    },
    [params, prefixPath],
  );

  return (
    <Wrapper id="rev-ArticleDetailPage" mobile={mobile}>
      {article && (
        <Helmet>
          <title>{F(article, 'html_title') || F(article, 'title') || ''}</title>
          <meta charset="utf-8" />
          <meta property="og:title" content={F(article, 'title') || ''} />
          <meta property="og:image" content={article.image || ''} />
          <meta property="og:image:width" content="300" />
          <meta property="og:image:height" content="300" />
          <meta name="title" content={F(article, 'title') || ''} />
          <meta name="description" content={F(article, 'outline') || ''} />
          <meta name="image" content={article.image || ''} />
        </Helmet>
      )}

      {_renderCustomSection('A')}

      {article && article.labels && article.labels[0] && (
        <div className="top">
          <BreadcrumbBar
            cat={article.labels[1]}
            updateCat={(nextCat) => {
              AppActions.navigate(`${listViewPath}?cat=${nextCat}`);
            }}
            categoryDisplayMap={categoryDisplayMap}
            instanceId={article.id}
            instanceDisplay={article.title}
            updateInstance={(nextId) => {
              AppActions.navigate(`${prefixPath}?id=${nextId}`);
            }}
          />
        </div>
      )}

      <div className="content">
        <div className="detail">
          {_renderCustomSection('B')}

          <div>
            {article && (
              <div className="main">
                <img
                  className="article-image"
                  alt="article-img"
                  src={article.image || '../../images/empty-img.png'}
                />
                <div className="article-content">
                  <div className="date">
                    {L('updated')}：
                    {formatDateTimeStr(new Date(article.updated))}
                  </div>
                  <h1 className="title">{F(article, 'title')}</h1>
                  <div className="line" />
                  <div className="content">
                    <RichTextPreview
                      content={F(article, 'content')}
                      key={article.id + LocaleOutlet.getValue().lang}
                    />
                  </div>
                  <div className="line" />
                </div>
                <div className="tail">
                  <div className="shares">
                    Share：
                    <button
                      className="circle-button"
                      onClick={() => copyShareUrl()}>
                      <LinkIcon
                        size={26}
                        style={{color: 'var(--primaryColor)'}}
                      />
                    </button>
                  </div>
                  <div className="buttons">
                    <div className="container">
                      {nextArticle && (
                        <a
                          target="_blank"
                          href={`/article?id=${nextArticle.id}`}
                          rel="noreferrer">
                          <button className="rectangle-button">
                            <ArrowPrevious size={25} />
                            <label>{L('next_article')}</label>
                          </button>
                        </a>
                      )}
                    </div>
                    <div className="gutter" />
                    <div className="container">
                      {previousArticle && (
                        <a
                          target="_blank"
                          href={`/article?id=${previousArticle.id}`}
                          rel="noreferrer">
                          <button className="rectangle-button">
                            <label>{L('prev_article')}</label>
                            <ArrowNext size={25} />
                          </button>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {_renderCustomSection('C')}
          </div>

          {_renderCustomSection('D')}
        </div>
      </div>

      {_renderCustomSection('E')}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: var(--topNavBarHeight) 20px 80px 20px;
  background-color: #f7f7f7;

  & > .top {
    max-width: 1000px;
    margin: 35px auto 12px auto;

    display: flex;
    justify-content: center;
  }

  & > .content {
    max-width: 1000px;
    margin: 23px auto;
    display: flex;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

    & > .detail {
      flex: 1;
    }
  }

  & .main {
    & img.article-image {
      width: 100%;
      height: auto;
      margin-bottom: 20px;
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;
      overflow: hidden;
    }

    & div.article-content {
      padding: ${(props) => (props.mobile ? '24px 26px' : '24px 80px')};

      & > .date {
        margin-bottom: 20px;
        font-size: 1rem;
        color: #909090;
      }

      & > .title {
        margin-bottom: 28px;
        font-weight: 500;
        color: #5dacb7;
      }

      & > .content {
        padding: 25px 0px;
      }

      & > .line {
        width: 100%;
        height: 1px;
        background-color: #d3d3d3;
      }
    }

    & .tail {
      padding: ${(props) =>
        props.mobile ? '0px 26px 50px 26px' : '0px 80px 50px 80px'};

      & > .shares {
        margin-bottom: 32px;
        display: flex;
        justify-content: ${(props) => (props.mobile ? 'flex-end' : 'center')};
        align-items: center;
        font-size: 1rem;
        color: #909090;

        & > .circle-button {
          cursor: pointer;
          margin: 10px;
          border-radius: 50%;
          border: 0px;
          width: 42px;
          height: 42px;
          background-color: #ffffff;
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

          &:hover {
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
          }
        }
      }

      & > .buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > .container {
          flex: 1;
        }

        & > .gutter {
          width: 20px;
        }

        & .rectangle-button {
          width: 100%;
          cursor: pointer;
          border-radius: 15px;
          border: 0px;
          padding: 16px;
          height: 60px;
          background-color: var(--primaryColor);
          font-size: 1rem;
          color: #ffffff;

          & > label {
            margin: 0px 10px;
          }
        }
      }
    }
  }
`;

export default withPageEntry(ArticleDetail);
